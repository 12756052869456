// Licensed to the Software Freedom Conservancy (SFC) under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  The SFC licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//   http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing,
// software distributed under the License is distributed on an
// "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
// KIND, either express or implied.  See the License for the
// specific language governing permissions and limitations
// under the License.

import React, { ReactNode } from 'react'
import { Box, Link, Typography } from '@material-ui/core'

class Footer extends React.Component<{}, {}> {
  render (): ReactNode {
    // noinspection HtmlUnknownAnchorTarget
    return (
      <Box pt={4}>
        <Typography variant='body2' color='textSecondary' align='center'>
          <Link href='#/help'>
            Help
          </Link>
          {' - All rights reserved - '}
          <Link href='https://sfconservancy.org/' target='_blank' rel='noreferrer'>
            Software Freedom Conservancy
          </Link>{' '}
          {new Date().getFullYear()}.
        </Typography>
      </Box>
    )
  }
}

export default Footer
