// Licensed to the Software Freedom Conservancy (SFC) under one
// or more contributor license agreements.  See the NOTICE file
// distributed with this work for additional information
// regarding copyright ownership.  The SFC licenses this file
// to you under the Apache License, Version 2.0 (the
// "License"); you may not use this file except in compliance
// with the License.  You may obtain a copy of the License at
//
//   http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing,
// software distributed under the License is distributed on an
// "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
// KIND, either express or implied.  See the License for the
// specific language governing permissions and limitations
// under the License.

import red from '@material-ui/core/colors/red'
import { createMuiTheme, Theme } from '@material-ui/core/styles'
import typography from './typography'

// A custom theme for this app
const theme: Theme = createMuiTheme({
  palette: {
    primary: {
      main: '#615E9B'
    },
    secondary: {
      main: '#F7F8F8'
    },
    error: {
      main: red.A400
    },
    background: {
      default: '#F7F8F8'
    }
  },
  typography
})

export default theme
